
.role__tab__content {
  .card-body {
    padding: 0;
  }
}

.role__list {
  &__item {
    border-radius: 0;
  }
}

.role__observer {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
